




































































import { defineComponent, ref, computed, watch, toRefs, PropType } from "@vue/composition-api";
import { DocumentHeadingModel, OrganizationMemberProjectModel } from "@/api/OtiumAppApi";
import Vue from "vue";

export default defineComponent({
  name: "ProjectEditMember",
  props: {
    member: {
      type: Object as PropType<OrganizationMemberProjectModel>,
      required: true
    },
    projectDocumentMap: {
      type: Object as PropType<{ [key: string]: DocumentHeadingModel }>,
      required: true
    }
  },
  emits: ["change", "remove"],
  setup(props, { root, emit }) {
    const { member, projectDocumentMap } = toRefs(props);

    const editingMember = ref<OrganizationMemberProjectModel | null>(null);
    watch(member, () => {
      editingMember.value = member.value;
    });
    editingMember.value = member.value;

    const availableRoles = ref<{ [key: number]: string }>({
      1: "Writer",
      2: "Reviewer"
    });
    const availableRolesSelect = computed(() =>
      Object.entries(availableRoles.value).map(([key, value]) => ({ value: parseInt(key), text: value }))
    );
    const allDocumentsSelect = ref([
      { value: false, text: "Specific documents only" },
      { value: true, text: "All documents" }
    ]);
    const availableDocumentsToAdd = computed(() =>
      Object.entries(projectDocumentMap.value)
        .filter(([key, value]) => !member.value.documents.includes(key))
        .map(([key, value]) => ({ value: value.documentId, text: value.name }))
    );
    const canAddDocument = computed(
      () =>
        !member.value.hasAllDocuments &&
        availableDocumentsToAdd.value.length > 0 &&
        !editingMember.value?.documents.some((d) => d == "")
    );

    function addDocumentClick() {
      if (editingMember.value == null) return;
      editingMember.value.documents.push("");
    }
    function addDocumentSelected(documentId: string) {
      if (editingMember.value == null) return;
      const index = editingMember.value.documents.findIndex((d) => d == "");
      Vue.set(editingMember.value.documents, index, documentId);
    }
    function removeDocument(documentIndex: number) {
      if (editingMember.value == null) return;
      editingMember.value.documents.splice(documentIndex, 1);
    }

    function removeMember() {
      emit("remove");
    }

    return {
      editingMember,

      availableRolesSelect,
      allDocumentsSelect,
      availableDocumentsToAdd,
      canAddDocument,

      addDocumentClick,
      addDocumentSelected,
      removeDocument,

      removeMember
    };
  }
});
