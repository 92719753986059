























































































import Vue from "vue";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";
import OtiumBasePage from "../OtiumBasePage.vue";
import DashboardProjectSummary from "./DashboardProjectSummary.vue";
import CreateProjectDialog from "@/components/dashboard/CreateProjectDialog.vue";
import {
  ProjectsClient,
  CreateProjectModel,
  ProjectSummary,
  ProjectTagModel,
  ICreateProjectModel
} from "@/api/OtiumAppApi";
import { useUserStore } from "@/stores/userStore";
import dayjs from "dayjs";

export default defineComponent({
  components: {
    OtiumBasePage,
    DashboardProjectSummary,
    CreateProjectDialog
  },
  setup(props, { root }) {
    const projects = ref<Array<ProjectSummary>>([]);
    const client = new ProjectsClient();
    const userStore = useUserStore();
    const tags = ref({} as { [key: string]: ProjectTagModel });
    const filterTag = ref(null as string | null);

    const sortedTags = computed(() => {
      const tagMap = tags.value;
      const sortedTags: ProjectTagModel[] = [];
      Object.keys(tagMap).forEach((key) => {
        sortedTags.push(tagMap[key]);
      });
      return sortedTags.sort((a, b) => a.tagName.localeCompare(b.tagName));
    });

    enum SortFields {
      StartDateDesc,
      StartDateAsc
    }

    const loadingProjects = ref(false);
    const fetchProjects = async () => {
      loadingProjects.value = true;
      projects.value = await client.userProjects();
      projects.value.forEach((project) =>
        project.tags.forEach((tag) => {
          const tagMap = tags.value;
          if (tagMap[tag.tagId] == undefined) Vue.set(tagMap, tag.tagId, tag);
        })
      );
      loadingProjects.value = false;
    };

    onMounted(async () => {
      await fetchProjects();
    });

    const searchTerm = ref("");
    const sortBy = ref(SortFields.StartDateDesc);

    const filteredProjects = computed(() => {
      let filteredProjects = projects.value.filter(
        (project) =>
          project.name.toLocaleUpperCase().includes(searchTerm.value.toLocaleUpperCase()) &&
          (filterTag.value == null || project.tags.some((tag) => tag.tagId == filterTag.value))
      );
      switch (sortBy.value) {
        case SortFields.StartDateAsc:
          filteredProjects.sort((a, b) => dayjs(a.createdOn).unix() - dayjs(b.createdOn).unix());
          break;
        case SortFields.StartDateDesc:
          filteredProjects.sort((a, b) => dayjs(b.createdOn).unix() - dayjs(a.createdOn).unix());
          break;
      }

      if (organizationFilter.value != "all") {
        filteredProjects = filteredProjects.filter(
          (p) => p.organizationName == organizationFilter.value
        );
      }

      return filteredProjects;
    });

    const createProject = async (model: ICreateProjectModel) => {
      await client.create(new CreateProjectModel(model));

      await fetchProjects();
      //refresh user profile
      await userStore.getUserProfile();
    };

    const goToArchived = () => {
      root.$router.push({ name: "ArchivedProjects" });
    };

    const organizationFilter = ref("all");
    const organizations = computed(() => {
      const names = projects.value.map((p) => p.organizationName);
      console.log(names);
      return [...new Set(names)];
    });

    return {
      projects,
      sortedTags,
      filterTag,
      createProject,
      searchTerm,
      sortBy,
      SortFields,
      filteredProjects,
      goToArchived,
      userStore,
      organizations,
      organizationFilter,
      loadingProjects
    };
  }
});
