




































































































































































































































import { defineComponent, ref, computed, toRefs } from "@vue/composition-api";
import {
  AddProjectMemberModel,
  DocumentHeadingModel,
  OrganizationMemberProjectModel,
  OrganizationMemberSummaryModel,
  ProjectRole,
  ProjectsClient,
  ProjectSummary,
  UpdateProjectMemberModel
} from "@/api/OtiumAppApi";
import { useUserStore } from "@/stores/userStore";
import ProjectEditMember from "./ProjectEditMember.vue";
import Vue from "vue";

export default defineComponent({
  name: "DashboardProjectSummary",
  components: {
    ProjectEditMember
  },
  props: {
    project: {
      type: ProjectSummary,
      required: true
    }
  },
  setup(props, { root }) {
    const { project } = toRefs(props);
    const projectClient = new ProjectsClient();
    const userStore = useUserStore();
    const hovered = ref(false);

    const isAdmin = computed(
      () => userStore.userProfile?.adminOrganizationId == project.value.organizationId
    );

    // maps enum values to the role names
    const availableRoles = ref<{ [key: number]: string }>({
      1: "Writer",
      2: "Reviewer"
    });

    const editingProjectMembers = ref<Array<OrganizationMemberProjectModel> | null>(null);
    const organizationMembersNotOnProject = ref<Array<OrganizationMemberSummaryModel> | null>(null);
    const projectDocumentMap = ref<{ [key: string]: DocumentHeadingModel }>({});

    async function loadProjectMembers() {
      project.value.contributors = await projectClient.getAllMembers(
        project.value.organizationId,
        project.value.id
      );
      organizationMembersNotOnProject.value = await projectClient.getAllAvailableMembers(
        project.value.organizationId,
        project.value.id
      );
    }

    async function loadProjectDetails() {
      await loadProjectMembers();
      var projectDocuments = project.value.documents;

      projectDocuments.forEach((d) => {
        Vue.set(projectDocumentMap.value, d.documentId, d);
      });
    }

    const editingOrganizationMembersNotOnProject = computed(() =>
      organizationMembersNotOnProject.value?.filter(
        (a) =>
          !editingProjectMembers.value?.some((b) => b.organizationMemberId == a.organizationMemberId)
      )
    );

    const newMember = ref<OrganizationMemberSummaryModel | null>(null);
    function addNewMember() {
      if (newMember.value == null) return;
      editingProjectMembers.value?.push(
        new OrganizationMemberProjectModel({
          organizationMemberProjectId: "",
          organizationMemberId: newMember.value.organizationMemberId,
          fullName: newMember.value.fullName,
          email: newMember.value.email,
          hasAllDocuments: true,
          role: ProjectRole.Writer,
          documents: []
        })
      );
    }

    function removeMember(memberIndex: number) {
      const member = editingProjectMembers.value?.splice(memberIndex, 1)[0];
      if (member == undefined) return;
      organizationMembersNotOnProject.value?.push(
        new OrganizationMemberSummaryModel({
          organizationMemberId: member.organizationMemberId,
          fullName: member.fullName,
          email: member.email
        })
      );
    }

    const isEditing = ref(false);
    const isSaving = ref(false);
    async function openEdit() {
      if (!isAdmin.value) return;
      await loadProjectDetails();
      editingProjectMembers.value =
        project.value.contributors?.map((member) => {
          const newMemberObject = Object.assign({}, member);
          newMemberObject.documents = [...newMemberObject.documents];
          return newMemberObject;
        }) ?? null;
      isEditing.value = true;
    }

    async function saveEdit() {
      const currentProject = project.value as ProjectSummary;
      const existingMembers = project.value.contributors;
      const editedMembers = editingProjectMembers.value;
      if (existingMembers == null || editedMembers == null) return;

      isSaving.value = true;

      // add added members
      const addedMembers = editedMembers.filter(
        (a) => !existingMembers.some((b) => b.organizationMemberId == a.organizationMemberId)
      );
      for (let i = 0; i < addedMembers.length; i++) {
        const member = addedMembers[i];
        await projectClient.addMemberToProject(
          new AddProjectMemberModel({
            organizationId: currentProject.organizationId,
            organizationMemberId: member.organizationMemberId,
            projectId: currentProject.id,
            hasAllDocuments: member.hasAllDocuments,
            role: member.role,
            documentIds: !member.hasAllDocuments
              ? member.documents.filter((x) => x != null && x != "") // remove blank document ids
              : [] // empty if they have all documents
          })
        );
      }

      // update updated members
      const updatedMembers = editedMembers
        .map((editedMember, editedIndex) => {
          // get matching member index
          const existingIndex = existingMembers.findIndex(
            (existingMember) => existingMember.organizationMemberId == editedMember.organizationMemberId
          );
          return { editedIndex, existingIndex };
        })
        .filter((x) => x.existingIndex != -1) // exclude added members
        .map(({ editedIndex, existingIndex }) => ({
          // convert indexes to the actual object
          edited: editedMembers[editedIndex],
          existing: existingMembers[existingIndex]
        }))
        .filter(({ edited, existing }) => {
          // check if the member has been updated
          if (edited.role != existing.role) return true;
          if (edited.hasAllDocuments != existing.hasAllDocuments) return true;
          if (edited.hasAllDocuments) return false; // we don't care about the allowed documents if they are allowed all documents
          edited.documents = edited.documents.filter((x) => x != null && x != "");
          return !(
            // check if every document has a match
            (
              edited.documents.every((ed) => existing.documents.some((ex) => ex == ed)) &&
              existing.documents.every((ex) => edited.documents.some((ed) => ed == ex))
            )
          );
        });
      for (let i = 0; i < updatedMembers.length; i++) {
        const member = updatedMembers[i].edited;
        await projectClient.updateMemberToProject(
          member.organizationMemberProjectId,
          new UpdateProjectMemberModel({
            organizationMemberProjectId: member.organizationMemberProjectId,
            organizationId: currentProject.organizationId,
            hasAllDocuments: member.hasAllDocuments,
            role: member.role,
            documentIds: !member.hasAllDocuments ? member.documents : []
          })
        );
      }

      // remove removed members
      const removedMembers = existingMembers.filter(
        (a) => !editedMembers.some((b) => b.organizationMemberId == a.organizationMemberId)
      );
      for (let i = 0; i < removedMembers.length; i++) {
        const member = removedMembers[i];
        await projectClient.deleteMemberFromProject(
          currentProject.organizationId,
          member.organizationMemberProjectId
        );
      }

      await loadProjectMembers();

      isEditing.value = false;
      isSaving.value = false;
    }

    function cancelEdit() {
      isEditing.value = false;
    }

    function viewProject() {
      if (isEditing.value) return;
      root.$router.push({ name: "Project", params: { projectId: project.value.id } });
    }

    return {
      hovered,
      isAdmin,
      availableRoles,

      editingProjectMembers,
      editingOrganizationMembersNotOnProject,
      projectDocumentMap,

      newMember,
      addNewMember,
      removeMember,
      loadProjectDetails,

      isEditing,
      isSaving,
      openEdit,
      saveEdit,
      cancelEdit,

      viewProject
    };
  }
});
