






















































































import {
  ProjectsClient,
  CreateProjectModel,
  ICreateProjectModel,
  OrganizationPermissionType
} from "@/api/OtiumAppApi";
import { useUserStore } from "@/stores/userStore";
import { ref, defineComponent } from "@vue/composition-api";

export default defineComponent({
  emits: ["createProject"],
  setup(props, { emit, refs }) {
    const displayCreateProjectDialog = ref(false);
    const isFormValid = ref(true);
    const projectName = ref("");
    const rules = [(v: string) => !!v || "Required"];
    const errorMessage = ref("");
    const creatingProject = ref(false);
    const isProjectNameValid = ref(false);

    const client = new ProjectsClient();
    const userStore = useUserStore();

    const canAddAnotherProject = ref(false);

    const organizationChanged = async () => {
      checkProjectName();
      checkProjectAvailable();
    };

    const checkProjectAvailable = async () => {
      if (selectedOrganization.value) {
        const isAvailable = await client.checkActiveProjectAvailable(selectedOrganization.value);
        canAddAnotherProject.value = isAvailable;
      } else {
        canAddAnotherProject.value = false;
      }
    };

    const checkProjectName = async () => {
      isProjectNameValid.value = false;
      errorMessage.value = "";
      if (projectName.value === "" || !selectedOrganization.value) return;

      const model: ICreateProjectModel = {
        name: projectName.value,
        organizationId: selectedOrganization.value
      };

      const isNameAvailable = await client.checkProjectNameAvailable(model as CreateProjectModel);

      if (isNameAvailable) {
        isProjectNameValid.value = true;
        errorMessage.value = "";
      } else {
        errorMessage.value = "Project name already exists";
      }
    };

    //TODO: call `window.clearTimeout` when leaving page (may require Vue 3)
    let projectNameCheckDebounceTimeout = undefined as number | undefined;
    function checkProjectNameDebounced() {
      window.clearTimeout(projectNameCheckDebounceTimeout);
      projectNameCheckDebounceTimeout = window.setTimeout(checkProjectName, 250);
    }

    const reset = () => {
      (refs.createProjectForm as HTMLFormElement).reset();
      errorMessage.value = "";
      isProjectNameValid.value = false;
    };

    const createProjectClicked = async () => {
      await checkProjectName();
      if (!isFormValid.value || !isProjectNameValid.value || !canAddAnotherProject.value) return;
      creatingProject.value = true;
      const model: ICreateProjectModel = {
        name: projectName.value,
        organizationId: selectedOrganization.value!
      };
      emit("createProject", model);
      creatingProject.value = false;
      displayCreateProjectDialog.value = false;
      reset();
    };

    const organizations = ref<any[]>([]);
    const selectedOrganization = ref<string | null>(null);
    const getOrganizations = () => {
      const userOrgs = userStore.userProfileOrganizations;

      userOrgs?.forEach((org) => {
        if (org.organizationPermissions.includes(OrganizationPermissionType.CanCreateProject)) {
          organizations.value.push({ text: org.organizationName, value: org.organizationId });
        }
      });

      if (organizations.value.length === 1) {
        selectedOrganization.value = organizations.value[0].value;
        checkProjectAvailable();
      }
    };
    getOrganizations();

    function openDialog() {
      if (organizations.value.length == 1) {
        selectedOrganization.value = organizations.value[0].value;
      }
      displayCreateProjectDialog.value = true;
    }

    return {
      displayCreateProjectDialog,
      isFormValid,
      projectName,
      isProjectNameValid,
      rules,
      errorMessage,
      creatingProject,

      organizations,
      selectedOrganization,

      openDialog,
      checkProjectNameDebounced,
      checkProjectName,
      reset,
      createProjectClicked,
      organizationChanged,
      canAddAnotherProject
    };
  }
});
